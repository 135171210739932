export const navItems2 = [

  {
    id: 1,
    title: 'about',
    link: '/about',
  },

  {
    id: 2,
    title: 'products',
    link: '/products',

  },
  {
    id: 3,
    title: 'ignite Youth',
    link: '/ignite-youth',

  },
  {
    id: 4,
    title: 'careers',
    link: '/careers',

  },
  {
    id: 5,
    title: 'contact us',
    link: '/contact-us',

  },

];

export const blogDetails = [

  {
    id: 0,
    title: 'TECHNOLOGY',
    content: 'New Technology Make for solar Operation',
    details: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ',
    image: 'https://res.cloudinary.com/naijakids/image/upload/v1666309242/blogimg3_q3rvbo.png',
  },
  {
    id: 1,
    title: 'solar',
    content: 'New Technology Make for solar Operation',
    details: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ',
    image: 'https://res.cloudinary.com/naijakids/image/upload/v1666309242/blogimg2_a7sh4e.png',

  },
  {
    id: 2,
    title: 'solar',
    content: 'New Technology Make for solar Operation',
    details: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ',
    image: 'https://res.cloudinary.com/naijakids/image/upload/v1666309242/blogimg1_yzuten.png',
  }
  // },
  // {
  //   id: 3,
  //   title: 'technology',
  //   content: 'New Technology Make for solar Operation',
  //   details: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ',
  //   image: 'https://res.cloudinary.com/naijakids/image/upload/v1666309242/blogimg3_q3rvbo.png',

  // },
  // {
  //   id: 4,
  //   title: 'solar',
  //   content: 'New Technology Make for solar Operation',
  //   details: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ',
  //   image: 'https://res.cloudinary.com/naijakids/image/upload/v1666309242/blogimg2_a7sh4e.png',

  // }, {
  //   id: 5,
  //   title: 'solar',
  //   content: 'New Technology Make for solar Operation',
  //   details: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ',
  //   image: 'https://res.cloudinary.com/naijakids/image/upload/v1666309242/blogimg1_yzuten.png',

  // },

];

export const blogDetails2 = [

  {
    id: 0,
    title: 'TECHNOLOGY',
    content: 'New Technology Make for solar Operation',
    details: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ',
    image: 'https://res.cloudinary.com/naijakids/image/upload/v1666309242/blogimg3_q3rvbo.png',
  },
  {
    id: 1,
    title: 'solar',
    content: 'New Technology Make for solar Operation',
    details: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ',
    image: 'https://res.cloudinary.com/naijakids/image/upload/v1666309242/blogimg2_a7sh4e.png',

  },
  {
    id: 2,
    title: 'solar',
    content: 'New Technology Make for solar Operation',
    details: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ',
    image: 'https://res.cloudinary.com/naijakids/image/upload/v1666309242/blogimg1_yzuten.png',

  },

];

export const jobOpenings = [
  {
    id: 0,
    title: 'Experience Designer, Interaction',

  },
  {
    id: 1,
    title: 'Experience Designer, Interaction',

  },
  {
    id: 2,
    title: 'Experience Designer, Interaction',

  },
  {
    id: 3,
    title: 'Experience Designer, Interaction',

  },
  {
    id: 4,
    title: 'Experience Designer, Interaction',

  },
  {
    id: 5,
    title: 'Experience Designer, Interaction',

  },
];
