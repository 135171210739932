import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './PageNotFound.module.css';
// import imgError from '../../assets/images/error colored.png';
import { ReactComponent as ImageError } from '../../assets/images/error colored.svg';
// import NavbarGit from '../../components/NavbarGit/NavbarGit';
import Footer from '../../components/Footer/Footer';

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <>
      {/* <NavbarGit /> */}
      <div className={classes.not_found}>
        <h1>Oops! 404 error</h1>
        <ImageError />
        <h2>
          We sincerely apologize...
          This page is not found on our server
        </h2>
        <button type="button" onClick={() => { navigate('/'); }}>Go home</button>
        <div style={{ paddingBottom: '100px' }} />
      </div>
      <Footer />
    </>
  );
}

export default PageNotFound;
