import React from 'react'
import classes from './ContactUs.module.css'
import Layout from '../../Layout/Layout'

const ContactUs = () => {
    return (
        <Layout>
            <section className={classes.ContactUs}>
                <div className={classes.contact}>
                    <h1>
                        Want to Collaborate? Contact Us
                    </h1>
                    <div className={classes.contactform}>
                        <div>
                            <p className={classes.formtitle}>Contact Us</p>
                        </div>
                        <hr />
                        <form>
                            <div className={classes.formChild}>
                                <label>Full Name
                                </label>
                                <input type="text" placeholder='Enter Your Name' />
                                <label>Email
                                </label>
                                <input type="email" placeholder='Enter Your Email' />
                                <label >Company Email
                                </label>
                                <input type="email" placeholder='Enter Your Company Email' />
                                <label >Phone Number
                                </label>
                                <input type="number" placeholder='Enter Your Phone Number' />
                                <label >Your Message
                                </label>
                                <textarea placeholder='Enter Your Message...' />
                                <button>
                                    Send Message
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ContactUs
