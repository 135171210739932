import React, { useEffect } from 'react';
import Layout from '../../Layout/Layout';

function InterrnalErrorPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Layout>
        500 Error page
      </Layout>
    </div>
  );
}

export default InterrnalErrorPage;
