import React from 'react';
import classes from './Footer.module.css'
import { ReactComponent as Facebook } from '../../assets/images/facebook.svg';
import { ReactComponent as LinkedIn } from '../../assets/images/linkedin.svg';
import { ReactComponent as Instagram } from '../../assets/images/instagram.svg';
import { ReactComponent as Twitter } from '../../assets/images/twitter.svg';
import icelogoimg from '../../assets/images/icelogo.jpg'

const Footer = (props) => {
  const { Home } = props;
  return (
    <footer>

      <div className={classes.foot}>
        <div className={classes.footlogo}>
          <img src={icelogoimg} alt='' />
        </div>
        <div className={classes.smLinks}
          style={{ display: Home.Home === true ? 'none' : 'flex' }}
        >
          <Facebook />
          <LinkedIn />
          <Instagram />
          <Twitter />
        </div>
      </div>
    </footer>
  )
}

export default Footer
