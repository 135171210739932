import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function Seo({ title, description, link }) {
    return (
        <Helmet
            prioritizeSeoTags
        >

            <title>{title}</title>

            <meta
                name="description"
                content={description}
            />
            <meta
                itemprop="name"
                content={description}
            />
            <meta
                itemprop="description"
                content={description}
            />
            <meta
                itemprop="image"
                content="https://res.cloudinary.com/naijakids/image/upload/v1688462778/ice_logo_lgq4tb.png"
            />
            <meta property="og:url" content={link} />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content={description}
            />
            <meta
                property="og:description"
                content={description}
            />
            <meta
                property="og:image"
                content="https://res.cloudinary.com/naijakids/image/upload/v1688462778/ice_logo_lgq4tb.png"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content={description}
            />
            <meta
                name="twitter:description"
                content={description}
            />
            <meta
                name="twitter:image"
                content="https://res.cloudinary.com/naijakids/image/upload/v1688462778/ice_logo_lgq4tb.png"
            />
        </Helmet>
    )
}