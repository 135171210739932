/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import classes from './Navbar.module.css';
import { ReactComponent as IceLogo } from '../../assets/images/icenewlog.svg';
import { ReactComponent as Hamburger } from '../../assets/images/hamburger.svg';
import * as data from '../../data';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';
import icelogoimg from '../../assets/images/icelogo.jpg'


function NavbarComp() {
  const navigate = useNavigate();
  // set active tab and current page for navLinks when clicked
  // eslint-disable-next-line no-unused-vars
  const [activeTabId, setActiveTabId] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);

  useEffect(() => {
    setCurrentPage(
      sessionStorage.getItem('currentPage')
        ? Number(sessionStorage.getItem('currentPage'))
        : 0,
    );
    setActiveTabId(
      sessionStorage.getItem('currentPage')
        ? Number(sessionStorage.getItem('currentPage'))
        : 0,
    );
  }, []);

  const navitem = data.navItems2;
  // handles navlink id clicked and sets active tab and current page
  const handleClickTabLink = (id) => {
    setActiveTabId(id);
    setCurrentPage(id);
    sessionStorage.setItem('currentPage', id);
    // sessionStorage.setItem('activeTab', id);
  };
  const goHome = () => {
    sessionStorage.setItem('currentPage', 0);
    navigate('/');
  };

  return (
    <div className={classes.navbar_main}>
      <div className={classes.navbar}>
        <div className={classes.navbar_content}>
          <ul className={classes.navbar_links}>
            <li className={classes.navbar_link1}>
              <IceLogo onClick={goHome} />
            </li>

          </ul>
        </div>
        <div className={classes.navbar_content}>
        <ul className={classes.navbar_links}>
          {navitem.map((items) => {
              const { id, title, link } = items;
              return (
                <>
                  <li
                    key={id}
                    onClick={() => handleClickTabLink(id)}
                    className={classes.link}
                  >
                    <Link
                      to={`${link}`}
                      className={`${currentPage === id ? classes.ActiveTab : ''}`}
                    >
                      {capitalizeFirstLetter(title)}
                    </Link>
                  </li>

                </>

              );
            })}
          </ul>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
          <img src={icelogoimg} alt="" style={{ margin: "-45px", maxWidth: '135px', zIndex : '90'}} onClick={goHome} />

          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={classes.navbar_content}>
            <ul className={classes.navbar_links}>
              {navitem.map((items) => {
                const { id, title, link } = items;
                return (
                  <li
                    key={id}
                    onClick={() => handleClickTabLink(id)}
                    className={classes.link}
                  >
                    <Link
                      to={`${link}`}
                      className={`${currentPage === id ? classes.ActiveTab : ''
                        }`}
                    >
                      {capitalizeFirstLetter(title)}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <div className={classes.navbars}>
        <div className={classes.navbar_content}>
          <img src={icelogoimg} style={{ maxWidth: '135px'}} alt="" onClick={goHome} />
        </div>
        <div className={classes.navbar_content}>
          <Hamburger onClick={handleShow} />
        </div>
      </div>
    </div>
  );
}

export default NavbarComp;
