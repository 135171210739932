/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useRef } from 'react';
import classes from './HomeNew.module.css';
import Layout from '../../Layout/Layout';
import demo from '../../assets/images/demo.png';
import thumb from '../../assets/images/thumb.png'
import Seo from '../../helpers/Seo';
import two from '../../assets/images/two.png';
import one from '../../assets/images/one.png';
import three from '../../assets/images/three.png';

function HomeNew() {
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  useEffect(() => {
    // window.scrollTo(0, 0)
    sessionStorage.setItem('currentPage', 0);
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  const additionalContentRef = useRef();

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const additionalContentPosition = additionalContentRef.current.offsetTop;

    if (scrollPosition > additionalContentPosition / 2) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const Home = true;
  return (
    <>
      <Seo
        title="ICE SOLAR"
        description="ICE is deploying distributed microutilities to empower the underserved."
        link="https://www.icesolar.co/"
      />


      <Layout
      Home={Home}
      >
        <div className={classes.home_main} >
          <div className={classes.home}>
            <div className={classes.herosection}>
              <div className={classes.hero_container}>
                <img
                  src={thumb}
                  className={`${classes.thumb} ${classes.tiny}`}
                  alt="ice solar thumbnail"
                  style={{ opacity: isVideoLoaded ? 0 : 1 }}
                />
                <video src="https://res.cloudinary.com/naijakids/video/upload/v1689349908/hero_iytnoy.mp4" id="bgVideo" autoPlay loop muted playsInline
                  onLoadedData={onLoadedData}
                  style={{ opacity: isVideoLoaded ? 1 : 0 }}
                />
                <div className={classes.vanish} />
                <div className={classes.hero_content}>
                  <div className={classes.Content1}>

                    <>
                      <h1>Connecting the Unconnected</h1>
                      <h2>We build digital technologies to empower youth and support <br className={classes.break} /> underserved micro-businesses to do more.</h2>

                      <button>
                        Get in touch
                      </button>
                    </>
                  </div>
                  <div className={classes.Content2}>
                    <div className={classes.Content2Img}>
                      <img src='https://res.cloudinary.com/naijakids/image/upload/v1703317405/one_1_z23i4w.png' alt='' />
                      <div>
                        <h1>Clean Energy</h1>
                        <h2>We Support Underserved small businesses with their <br className={classes.break} /> transition to clean energy <br className={classes.break} /></h2>
                      </div>
                    </div>
                  </div>
                  <div className={classes.Content2}>
                    <div className={classes.Content2Img}>
                      <img src='https://res.cloudinary.com/naijakids/image/upload/v1703317433/two-removebg-preview_2_1_gd2zw8.png' alt='' />
                      <div>
                        <h1>Digital Transformation</h1>
                        <h2>We use digital technology to provide value added <br className={classes.break} />services to increase small buiness productivy </h2>
                      </div>
                    </div>
                  </div>
                  <div className={classes.Content2}>
                    <div className={classes.Content2Img}>
                      <img src='https://res.cloudinary.com/naijakids/image/upload/v1703317409/three_1_zea8og.png' alt='' />
                      <div>

                        <h1>Community Development</h1>
                        <h2>We provide digital upskilling to reach underserved  <br className={classes.break} /> communities and prepare youth for the future of work </h2>

                      </div>

                    </div>
                  </div>
                  <div className={classes.arrowimg}>

                    <img src='https://res.cloudinary.com/naijakids/image/upload/v1703323648/arrow-up_o3vbya.png' alt='' />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>

  );
}

export default HomeNew;
