/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import NavbarComp from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';

const Layout = (props) => {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <>
      <NavbarComp />
      {props.children}
      <Footer Home={props}/>
    </>
  );
};

export default Layout;
