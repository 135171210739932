/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
// import Footer from '../../components/Footer/Footer'
import { send } from 'emailjs-com';
// import NavbarGit from '../../components/NavbarGit/NavbarGit';
import classes from './GetInTouch.module.css';
// import Git from '../../components/GetInTouchComp/Git';
import Footer from '../../components/Footer/Footer';
import Seo from '../../helpers/Seo';


function GetInTouch() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const [loading, setLoading] = useState(false);
  const [toSend, setToSend] = useState({
    first_name: '',
    last_name: '',
    message: '',
    reply_to: '',
    phone_number: '',
  });
  // {full_name}}
  // {{phone_number}}
  // {{company_name}}
  // {{email}}
  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    send('service_d2h5nxp', 'template_p7ginyo', toSend, 'hvvdkaRq_KZK-Sz2_')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setLoading(false);
        // eslint-disable-next-line no-alert
        alert('email sent');
        // reset()
      })
      .catch((err) => {
        console.log('FAILED...', err);
        setLoading(false);
      });
  };
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <Seo
        title="Get in Touch"
        description="Get in touch with us"
        link="https://www.icesolar.co/get-in-touch"
      />
      {/* <NavbarGit /> */}
      <div className={classes.git_wrapper}>
        <div className={classes.git_main}>
          <div className={classes.git_title}>
            <h1>Get in Touch</h1>
            <h2>
              We cater to real-world problems and create sensible, scalable
              solutions; technically and commercially smart charging networks 
              {/* We
              cater to real-world problems and create sensible, scalable
              solutions; technically and commercially smart charging networks */}
              {' '}
            </h2>
          </div>
          <form className={classes.git_section} onSubmit={onSubmit}>
            <div className={classes.git_sectionMain}>
              <div className={classes.git_sectionMainLeft}>
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={toSend.from_name}
                  onChange={handleChange}
                />
                <div style={{ marginBottom: '22px' }} />
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={toSend.to_name}
                  onChange={handleChange}
                />
                <div style={{ marginBottom: '22px' }} />

                <input
                  type="email"
                  placeholder="Email Address"
                  name="reply_to"
                  value={toSend.reply_to}
                  onChange={handleChange}
                />
                <div style={{ marginBottom: '22px' }} />

                <input
                  type="number"
                  placeholder="Phone Number"
                  name="phone_number"
                />
              </div>
              <div className={classes.git_sectionMainRight}>
                <textarea
                  required
                  style={{}}
                  id="formMessage"
                  name="message"
                  placeholder="Your message"
                  value={toSend.message}
                  onChange={handleChange}
                >
                  Message
                </textarea>
              </div>
            </div>
            <span>
              <input type="checkbox" required />
              <label>I want to recieve news and updates once in awhile</label>
            </span>
            <div className={classes.submission}>
              {loading === true ? (
                <button className={classes.disabled} type="submit">
                  {' '}
                  Submitting....
                </button>
              ) : (
                <button type="submit"> Submit Message</button>
              )}
            </div>
          </form>

          <div className={classes.mobileNews}>
            <div className={classes.newsletter_wrapper}>
              <h1>Subscribe to our news letter</h1>
              <h2>Receive updates about our solutions and events</h2>
              <div className={classes.newsletter_button}>
                <input type="text" />
                <button type="button">Suscribe</button>
              </div>
            </div>
          </div>

          <div className={classes.gitarea}>

            <div className={classes.gitbox}>
              {/* <Git /> */}
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      {/* <FooterNew /> */}
      <Footer />
    </div>
  );
}

export default GetInTouch;
