import React from 'react'
import classes from './Collaborate.module.css'
const Collaborate = () => {
    return (
        <div className={classes.Collab}>
            <div className={classes.CollabContent}>
                <h1>Ready to Collaborate?</h1>
                <button>
                    Get in Touch
                </button>
            </div>
        </div>
    )
}

export default Collaborate
