import React from 'react'
import Layout from '../../Layout/Layout'
import classes from './Careers.module.css'
import Seo from '../../helpers/Seo'
const Careers = () => {
    return (
        <>
            <Seo
                title="Careers"
                description="Come Work With Us"
                link="https://www.icesolar.co/careers"
            />
            <Layout>
                <section className={classes.careers}>
                    <div className={classes.careerTop}>
                        <h1>
                            Come Work With Us
                        </h1>
                        <h2>
                            Are you looking to kickstart your career or an experienced professional looking to make a difference?
                        </h2>
                        <div className={classes.image}>
                            <div className={classes.wrapper}>
                                <img src='https://res.cloudinary.com/naijakids/image/upload/v1702824619/Frame_1000003261_suawzr.png' alt='' />
                            </div>
                        </div>
                    </div>
                    <div className={classes.careerOpen}>
                        <h1>
                            Our Openings
                        </h1>
                        <hr className={classes.horizontal} />
                        <h2>
                            No Open Roles
                        </h2>
                        <h3>Check back soon!</h3>
                        <hr className={classes.horizontal} />

                    </div>
                    <div className={classes.careercards}>

                        <div className={classes.cardbox}>
                            <div className={classes.card}>
                                <h1>
                                    Competitive Salary
                                </h1>
                                <h2>
                                    We offer competitive salaries and
                                    the opportunity for you to have
                                    good career progression
                                </h2>
                            </div>
                            <div className={classes.card}>
                                <h1>
                                    Impact Focused Work</h1>
                                <h2>
                                    We believe in creating a team-first organization where no individual is greater than the whole, making it one of their Best Places to Work
                                </h2>
                            </div>
                            <div className={classes.card}>
                                <h1>
                                    Generous PTO
                                </h1>
                                <h2>
                                    We're very ambitious but we also recognize the need to rest and recharge. We encourage you to take your PTO and enjoy our company holidays
                                </h2>
                            </div>
                        </div>
                    </div>
                </section>

            </Layout>
        </>
    )
}

export default Careers
